import { Component, Watch } from 'vue-property-decorator'
import { CommonBaseMixin } from './base'
import { Getter } from 'vuex-class'
import { AUTH_LOGOUT_REQUEST, SET_MIMIC_USER } from '../../store/actions/auth'
import { UnreadCountDto } from 'booksprout'
import slugify from 'slugify'
import axios, { AxiosResponse } from 'axios'

const document = require('global/document')
let maintenanceInterval: any = void 0

// @ts-ignore
@Component
export default abstract class CommonLayoutMixin extends CommonBaseMixin {
  showNotifications = false
  isUserMenuOpen = false
  isMainMenuOpen = false
  showDemoBanner = false
  showEnteringMaintenanceModeBanner = false
  showOfflineBanner = false
  nextMaintenanceTime = ''
  nextMaintenanceTimeInMinutes = ''

  @Watch('$route')
  onRouteChanged () {
    if (this.$q.screen.lt.md) {
      this.isMainMenuOpen = false
    }
  }

  @Getter('getUnreadMessageCount') unreadMessageCount!: number
  @Getter('getUnreadNotificationCount') unreadNotificationCount!: UnreadCountDto

  get canSwitchToMimicUser () {
    return this.authenticatedUser.canActAs &&
      this.authenticatedUser.canActAs.length > 0 &&
      !this.authenticatedUser.isAdmin // Don't show the switch button for admins
  }

  get displayName () {
    return this.mimicOrAuthenticatedUser?.name?.trim() || this.mimicOrAuthenticatedUser?.emailAddress || ''
  }

  get adminLinkForMimicUser (): string {
    if (this.isMimic) {
      const name = slugify(this.mimicUser.name || this.mimicUser.emailAddress + '', {
        strict: true
      })
      const url = `user/${this.mimicUser.id}/${name}`

      return this.linkToOtherApp('admin', url)
    }

    return this.linkToOtherApp('admin', 'dashboard')
  }

  get isOffline () {
    return this.nextMaintenanceTime === 'offline'
  }

  get headerOffset () {
    let totalOffset = 0
    if (this.showDemoBanner) {
      const demoBannerHeight = this.$q.screen.xs ? 138 : 72
      totalOffset += demoBannerHeight
    }

    // This will be set for "offline" or a date time when the maintenance will happen. We'll only show one banner
    // at a time so this can just add 65px
    if (this.nextMaintenanceTimeInMinutes || this.isOffline) {
      totalOffset += 65
    }

    return totalOffset
  }

  openHelpscout (app: string | undefined, showAskTab: boolean = false) {
    if (typeof window.Beacon === 'function' && typeof window.Beacon('info') === 'object') {
      const { isOpened } = window.Beacon('info').status
      window.Beacon(isOpened ? 'close' : 'open')

      if (showAskTab) {
        window.Beacon('navigate', '/ask/message/')
      }
    } else {
      if (app && app === 'reviewer') {
        window.open('https://help.booksprout.co/collection/173-reader-support')
      } else {
        window.open('https://help.booksprout.co/collection/124-author-support')
      }
    }
  }

  logout () {
    this.$store.dispatch(AUTH_LOGOUT_REQUEST).then(() => {
      void this.$router.push('/login')
    })
  }

  onShowSwitchUserClick () {
    this.$q.dialog({
      component: () => import('../SelectUserToSwitchDialog.vue'),
      parent: this
    })
  }

  showDemoBannerDialog (showFeedback: boolean) {
    this.$q.dialog({
      component: () => import('../DemoBannerDialog.vue'),
      parent: this,
      showFeedback
    })
  }

  checkForMaintenanceModeOrOffline () {
    const url = process.env.BUILD_MODE === 'master' ? 'https://booksprout.co' : 'https://beta.booksprout.co'
    axios({
      url: `${url}/entering-maintenance.txt`,
      method: 'get',
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then((enteringMaintenanceResponse: AxiosResponse) => {
      if (enteringMaintenanceResponse.status === 200) {
        const isoTimeString = enteringMaintenanceResponse.data
        this.nextMaintenanceTime = isoTimeString?.replace(/(\r\n|\n|\r)/gm, '')

        if (this.nextMaintenanceTime) {
          if (this.isOffline) {
            this.nextMaintenanceTimeInMinutes = ''
          } else {
            const nextMaintenanceDate = new Date(this.nextMaintenanceTime)
            const now = new Date()
            if (nextMaintenanceDate < now) {
              this.nextMaintenanceTimeInMinutes = ''
            } else {
              this.nextMaintenanceTimeInMinutes = Math.round((nextMaintenanceDate.getTime() - now.getTime()) / 60000) + ''
            }
          }
        }
      }
    })
  }

  onStopMimicClick () {
    void this.$store.dispatch(SET_MIMIC_USER, null)
  }

  mounted () {
    if (process.env.CLIENT) {
      requestAnimationFrame(() => {
        this.isMainMenuOpen = this.$q.screen.gt.sm
        this.showDemoBanner = document.location.href.indexOf('beta.') > -1
          // comment out if fed up of seeing this in local dev mode :P
          || document.location.href.indexOf('localhost:') > -1
      })
    }
  }

  created () {
    if (!maintenanceInterval) {
      this.checkForMaintenanceModeOrOffline()
      maintenanceInterval = setInterval(async () => {
        this.checkForMaintenanceModeOrOffline()
      }, 60000)
    }
  }

  updated () {
    // set scrollbar width css var so it can be used in calculations
    // see ArcInfoPanel for example
    // it is important to set this var in the later lifecycle hook than "mounted"
    // because "mounted" is called first on this mixin
    // and then within "ArcInfoPanel" component
    document.body.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.body.clientWidth}px`
    )

    // https://app.shortcut.com/booksprout/story/7209/bs-a-r-helpscout-beacon-changing-tutorials-based-on-url
    // see https://developer.helpscout.com/beacon-2/web/javascript-api/#beacon-event-eventobject
    if (typeof window.Beacon === 'function' && typeof window.Beacon('info') === 'object') {
      const { isOpened } = window.Beacon('info').status

      if (isOpened) {
        window.Beacon('event', {
          type: 'page-viewed',
          url: this.$router.currentRoute.fullPath,
          title: document.title,
        })
        window.Beacon('suggest')
      }
    }
  }
}
